// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Open+Sans&display=swap");

@font-face {
    font-family: 'Jokerman';
    src: url('../fonts/Jokerman.ttf'); /* IE9 Compat Modes */
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~line-awesome/dist/line-awesome/css/line-awesome.min.css';
@import '~swiper/swiper-bundle.min.css';
@import '~jquery-fancybox/source/scss/jquery.fancybox';
@import '~air-datepicker/dist/css/datepicker.min.css';
@import '~aos/dist/aos.css';

@keyframes bluefade {
    from {background-color: transparent;}
    to {background-color: #059;}
}
@keyframes orange-fade {
    from {background-color: transparent;}
    to {background-color: #EB9E45;}
}

.text-jokerman {
    font-family: Jokerman, Arial, san-serif !important;
}

.antialiased{
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

body {
    padding: 0;
    margin: 0;
    //font-family: 'Nunito';
    //font-family: Montserrat, Arial, sans-serif;

    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;

    font-size: 13px;
    line-height: 1.5;
    font-family: Montserrat,Arial,sans-serif;
    color: #333;
    font-weight: 400;

}


#top-bar {
    height: 60px;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    //background-color: rgba(34,34,34,0.95);
    background-color: rgba(0,0,0,0.9);
    color: #fff;
    z-index: 10;
}

.navbar {
    padding: 0 !important;
}
.navbar-brand {
    width: 90px !important;
    >img {
        position:absolute !important;
        top:.2rem !important;
    }
}

.navbar-nav {
    height: 60px;
    line-height: calc(60px - 1rem);

    .na-link {
        color: rgba(255, 255, 255, 1);
        font-size: 1rem;
    }

    a {
        &:not(.dropdown-item) { padding: .5rem 1.6rem !important; }

        &:hover {
            animation: orange-fade 0.3s forwards;
        }
    }

}


#carousel {
    position: relative;
    left: 0px;
    top: 0px;
    background-color: #c4c4c4;
    z-index: 1;
    clear: both !important;
    max-height: 700px;
    overflow: hidden !important;
}

.carousel-control-prev, .carousel-control-next {
    width: 5% !important;
}


.carousel-indicators {
    bottom: 55px !important
}


.carousel-item.active img{
    -webkit-animation: myMove 10s 1 ease-out forwards;
    -moz-animation: myMove 10s 1 ease-out forwards;
    -o-animation: myMove 10s 1 ease-out forwards;
    -ms-animation: myMove 10s 1 ease-out forwards;
    animation: myMove 10s 1 ease-out forwards;
}
@keyframes myMove {
    from { transform: scale(1.0,1.0); transform-origin: 50% 50%; }
    to { transform: scale(1.1,1.1); transform-origin: 50% 0%; }
}
@-webkit-keyframes myMove {
    from { -webkit-transform: scale(1.0,1.0); -webkit-transform-origin: 50% 50%; }
    to { -webkit-transform: scale(1.1,1.1); -webkit-transform-origin: 50% 0%;  }
}
@-o-keyframes myMove {
    from { -o-transform: scale(1.0,1.0); -o-transform-origin: 50% 50%; }
    to { -o-transform: scale(1.1,1.1); -o-transform-origin: 50% 0%;  }
}
@-moz-keyframes myMove {
    from { -moz-transform: scale(1.0,1.0); -moz-transform-origin: 50% 50%; }
    to { -moz-transform: scale(1.1,1.1); -moz-transform-origin: 50% 0%; }
}
@-ms-keyframes myMove {
    from { -ms-transform: scale(1.0,1.0); -ms-transform-origin: 50% 50%; }
    to { -ms-transform: scale(1.1,1.1); -ms-transform-origin: 50% 0%; }
}

.boxed-content {
    margin: auto;
    max-width: 1100px;
    width: 98%;
}

.tint {
    position: relative;
    z-index: 2;
    height: 300px;
    background-color: rgba(0,0,0,0.4);
}

#page-banner {
    max-height:200px !important;
    overflow: hidden !important;
    img {
        position: relative;
        z-index: 1;
    }
}
.banner-content {
    position: absolute;
    top: 2rem; left: 0px; right: 0px;
    z-index: 3;
    color: #fff;
    padding-top: 70px;
}

.page-area {
    min-height: 300px;
    padding-top: 1rem;
}

footer {
    margin: 0; padding: 0;
    background-color: #222;
    min-height: 180px;
    >.footer-content {
        padding: 2.5rem 2rem;
        color: #eee;
    }
    h3 {
        font-size: 13px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        margin-top: 20px;
    }
}

.tours-list {

}



.dropdown:hover > .dropdown-menu {
    display: block;
}
.dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}


.btn_1, a.btn_1 {
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: #fff;
    background: #008489;
    cursor: pointer;
    padding: 7px 20px;
    display: inline-block;
    outline: 0;
    font-size: 13px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-weight: 600;
}

.ribbon_3 {
    position: absolute;
    left: 10px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #638908;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #638908;
    }

    span {
        font-size: 10px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(
                -45deg
        );
        -webkit-transform: rotate(
                -45deg
        );
        width: 100px;
        display: block;
        background: #79a70a;
        background: linear-gradient(#9bc90d 0,#79a70a 100%);
        box-shadow: 0 3px 10px -5px #000;
        text-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
        position: absolute;
        top: 19px;
        left: -21px;
        &::before {
            border-left: 3px solid #a3362a;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #a3362a;
        }
        &::after {
            border-left: 3px solid transparent;
            border-right: 3px solid #a3362a;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #a3362a;
        }
    }
}

ul.add_info{
    list-style-top: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 5px;
        border: 1px solid #ededed;
        text-align: center;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: .2rem;
        padding: .1rem .3rem;
    }
}
.tours-list {
    margin: 0;
    padding: 0;
}
.tour-list-item {
    margin-bottom: 30px;
    background-color: #fff;
    display: block;
    -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    .img_list {
        overflow: hidden;
        min-height: 210px;
        max-height: 250px;
        text-align: center;
        position: relative;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        img {
            text-align:center;
            display:block;
        }

    }

    .tour_list_desc {
        padding: 10px 1rem;
        border-right: 1px solid #ededed;
        height: 220px;
        line-height: 17px;

        h3 {
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 10px;
        }
        p {
            font-size: 13px;
        }
    }

    .price_list {
        display: table;
        height: 220px;
        font-size: 38px;
        color: #e74c3c;
        width: 100%;
        margin-left: -15px;

        div {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            sup {
                font-size: 20px;
                position: relative;
                top: -10px;
            }
            .normal_price_list {
                display: block;
                text-decoration: line-through;
                color: #999;
                font-size: 20px;
                margin-top: 10px;
            }
            p {
                padding: 0;
                margin: 0;
            }

            small {
                font-size: 11px;
                color: #999;
                display: block;
                margin-bottom: 10px;
                line-height: 12px;
            }
        }


    }

    ul.add_info {
        list-style-type: none;
        display: inline-block;
        border-radius: .3rem;
        padding: .1rem .4rem;
    }

}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
    }

}



.partners {
    max-height: 240px !important;
    padding: 30px 1rem;
    width: 100%;
    background-image: url('../images/grass.jpg');
    background-attachment: fixed;
}
.partners .swiper-container {
    width: 100%;
    height: 100%;
}
.partners .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    height: 180px;
    width: 25% !important;
    overflow: hidden;
}
.partners .swiper-slide img {
    //display: block;
    width: 180px;
    height: 100%;
    object-fit: cover;
    border-radius: 50% !important;
    overflow: hidden;
}
.partners .partner:hover img {
    border: 2px solid #EB9E45;
}

.tooltip_styled {
    display: inline;
    position: relative;
    z-index: 999;
    .tooltip-item {
        cursor: pointer;
        display: inline-block;
    }
}

.btn-custom {
    color: #fff;
    background-color: #EB9E45;
    border-color: #EB9E45;
}

.note-editable {
    font-family: 'Monserat' !important;
    font-size: 13px !important;
    line-height: 1.5;
}

.bg-pal1 { background-color: $pal1;}
.bg-pal2 { background-color: $pal2;}
.bg-pal3 { background-color: $pal3;}
.bg-pal4 { background-color: $pal4;}
.bg-pal5 { background-color: $pal5;}
.bg-pal6 { background-color: $pal6;}
.bg-pal7 { background-color: $pal7;}
.bg-pal8 { background-color: $pal8;}





/** MEDIA QUERIES **/
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .navbar-brand {
        width: 30px !important;
    }
    .carousel-caption {
        h1 {
            font-size: 1rem !important;
        }
        h3 {
            font-size: .8rem !important;
        }
    }
    .push-top {
        margin-top: -65px !important;
    }
    .boxed-content {
        width: 96% !important;
    }
    .carousel-indicators {
        bottom: 2px !important
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .navbar-nav {
        li.nav-item {
            background-color: rgba(0, 0, 0, 0.9) !important;
        }
    }
    .navbar-brand > img {
        width: 54px;
    }
    .navbar-toggler{
        margin-top: .6rem;
    }
    .carousel-caption {
        h1 {
            font-size: 1.2rem !important;
        }
        h3 {
            font-size: 1rem !important;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {  }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {  }
