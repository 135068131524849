// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;


$pal1: #464547;
$pal2: #796d5d;
$pal3: #baae97;
$pal4: #a6a7a5;
$pal5: #DEAE83;
$pal6: #615e59;
$pal7: #baae97;
$pal8: #a6a7a5;
